import * as Sentry from "@sentry/gatsby";

Sentry.init({
  dsn: "https://5c4eb725dc7c88d184ec853ae35bb75a@o286509.ingest.sentry.io/4505742272692224",
  environment: process.env.GATSBY_STAGE === 'prod' ? 'production' : 'staging',
  beforeSend: (event) => {
    if (event.request?.url && (event.request.url.indexOf('/download') > -1 || event.request.url.indexOf('/optum') > -1)) {
      return event;
    }
    return null;
  }
});